<template>
    <div class="container">

        <div class="table">
            <p>题型题量分析</p>
            <el-table :data="queStatVos" border style="width: 100%" size="mini" max-height="500px">
                <el-table-column prop="type" label="题型">
                    <template slot-scope="scope">
                        {{ scope.row.type == 1 ? '单选题' : scope.row.type == 2 ? '多选题' : '判断题' }}
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="题量" > </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate}}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>难度分析</p>
            <el-table :data="diffStatVos" border style="width: 100%" size="mini" max-height="500px">
                <el-table-column prop="difficulty" label="试题难易度">
                    <template slot-scope="scope">
                        {{difficultyName[scope.row.difficulty] }}
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="题量" > </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate}}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>一致性分析</p>
            <el-table :data="conStatVos" border style="width: 100%" size="mini" max-height="500px">
                <el-table-column prop="consistency" label="试题一致性">
                    <template slot-scope="scope">
                        {{ scope.row.consistency }}
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="题量" > </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate}}%
                    </template>
              </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>知识点分析</p>
            <el-table :data="knoStatVos" border style="width: 100%" size="mini" max-height="500px">
                <el-table-column prop="name" label="一级知识点">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="题量" > </el-table-column>
                <el-table-column prop="rate" label="占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate}}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>等级分析</p>
            <el-table :data="queLevelStatVos" border style="width: 100%" size="mini" max-height="500px">
                <el-table-column prop="type" label="试题等级">
                    <template slot-scope="scope">
                        {{ scope.row.level }}
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="题量" > </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate}}%
                    </template>    
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuestionStatic',
    data() {
        return {
            conStatVos: [],
            diffStatVos: [],
            knoStatVos: [],
            queLevelStatVos: [],
            queStatVos: [],
            difficultyName: ['', '容易', '较易', '一般', '较难', '困难']
        };
    },
    created() {
        this.tkStatistics()
    },
    methods: {
        async tkStatistics() {
            // todo 考试试卷
            const result = await this.Api.databaseManage.tkStatistics({});
            this.conStatVos = result.data.conStatVos;
            this.diffStatVos = result.data.diffStatVos;
            this.knoStatVos = result.data.knoStatVos;
            this.queLevelStatVos = result.data.queLevelStatVos;
            this.queStatVos = result.data.queStatVos;
        },

    }
};
</script>

<style scoped lang="scss">
.container {
    width: 100%;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .table {
        width: 45%;
        margin-right: 2%;

        p {
            color: #3bafda;
        }
    }

}

::v-deep .el-table__cell {
    text-align: center;
}
</style>